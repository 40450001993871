.table-info-widget {
  border: 1px solid #ededed;
  background: #FFFFFF;
  border-radius: 30px;

  .table-info-widget-item {
    padding: 25px 35px;
    border-bottom: 1px solid #ededed;

    .label {
      color: @colorMuted;
      .font-slim;
    }

    &:last-child {
      border-bottom: none;
    }

    @media @xs {
      text-align: center;
    }
    @media @sm {
      text-align: left;
    }
  }
}

