.our-wards-page {
  .gathering-closed {
    color: #27AE60;
    background: #27AE6010;
    position: relative;
    display: inline-block;
    border-radius: 20px;
    padding: 6px 30px;
    padding-left: 33px;
    padding-right: 15px;
    //.font-slim;
    .check{
      position: absolute;
      left: 13px;
      top: 7px;
      font-size: 13px;
    }
  }

  .page-title-container {
    width: 300px;
    text-align: center;
    margin: 0 auto;
    @media @sm {
      margin: 20px 0;
      text-align: left;
      width: 100% !important;
      float: none;
    }
  }

  .rub-sign-svg {
    width: 10px !important;
  }


  .item {
    text-align: center;
    min-width: 288px;
    min-height: 230px;
    background: #FFFFFF;
    border: 1px solid rgba(137, 146, 171, 0.2);
    border-radius: 15px;

    margin: 0 auto 20px auto;
    padding-bottom: 20px;

    img {
      width: 100%;
    }

    h2 {
      margin-bottom: 0;

      @media @sm {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }

    .image-container > div {
      border-radius: 100px !important;
      width: 110px;
      height: 110px;
      margin: 10px auto 0 auto;
      border: 5px solid #FFFFFF;
      box-sizing: border-box;
      filter: drop-shadow(10px 14px 24px rgba(224, 221, 232, 0.7));
    }

    @media @sm {
      min-width: 257px;
      min-height: 266px;
      .image-container > div {
        margin: 24px auto 0 auto;
      }
    }


    @media @md {

    }

    @media @lg {

    }

    @media @xl {

    }

    @media @xxl {

    }
  }

  .custom-tabs-widget-menu-container.our-wards {


    @media @sm {
      position: relative;
      display: inline-block;
      width: auto !important;
      button {
        width: max-content !important;
      }

      margin-top: 15px;
    }

    @media @xl {
      position: absolute;
      right: 0;
      top: 13px;
      width: max-content;
      button {
        width: max-content !important;
      }
    }

  }
}

