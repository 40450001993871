.about-page {

  .page-title-container {
    margin-bottom: 27px;
  }

  padding-top: 50px;

  .about-img-lg {
    display: inline-block;
    width: 143%;
    position: relative;
    left: -18%;
    top: 5%;
    @media @xl {
      top: 1%;
    }
  }

  .about-img-sm {
    left: -3%;
    position: relative;
    margin-bottom: -50px;
  }


  .mission {
    .img-md {
      position: relative;

      svg {
        position: absolute;
        right: 0;
        top: -35%;
        width: 100%;
      }
    }
  }

  .structure {
    .bg-img {

      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 30px;

      img {
        opacity: 0;
        width: 99%;
      }
    }

    .img-container {
      position: relative;
      margin-bottom: 30px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 82%;
        background: #d1d5de;
        border-radius: 30px;
        bottom: 0;
        z-index: -1;
        left: 0;
      }
    }
  }

  .docs {
    .doc-desc {
      @media @xs {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      @media @sm {
        text-align: left;
      }
      @media @md {
        text-align: left;
      }
      @media @lg {
        text-align: left;
      }
      @media @xl {
        text-align: center;
      }
    }
  }

  .values,
  .tasks {
    .task-item {
      text-align: left;
      margin-bottom: 50px;

      h1{
        border-radius: 100px;
        padding: 0 0 0 27px;
        background: #ededed;
        display: inline-block;
      }

      @media @xs{
        text-align: center;
      }
      @media @sm{
        text-align: left;
      }
      @media @xl{
        text-align: center;
      }
      .header {

      }

      .content {

      }
    }
  }

}

.about_map_container {
  .map {
    height: 500px;
    background-position: 100% 0;
    background-repeat: no-repeat;
    bottom: -150px;
    z-index: -1;
    position: relative;
    @media @sm {
      background-position: 55% 0;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 68%;
        height: 100%;
        background: rgb(255, 255, 255);
        opacity: 0.7;
        border-radius: 15%;
        //background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 1) 72%, rgba(255, 255, 255, 0.5913461538461539) 100%);
        //background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 72%, rgba(255, 255, 255, 0.5913461538461539) 100%);
        //background: linear-gradient(90deg, rgba(255, 255, 255, 1) 72%, rgba(255, 255, 255, 0.5913461538461539) 100%);
        //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
      }
    }
    @media @md {
      background-position: 50% 0;
      &:after {
        width: 15%;
        border-radius: 55%;
      }
    }
    @media @lg {
      background-position: 80% 0;
      &:after {
        width: 15%;
      }
    }
    @media @xl {
      background-position: 100% 0;
    }
  }

  .map-sm {
    position: relative;
    bottom: -90px;
    z-index: -1;
  }
}

.social-links-gray {
  position: relative;
  left: -8px;

  a {
    padding: 8px;
  }

  svg {
    border: 2px solid rgba(137, 146, 171, 0.2);
    border-radius: 100px;
    padding-top: 2px;

  }

  path {
    fill: rgba(137, 146, 171, 0.7);
  }
}

