@fontFamily: 'Core Rhino 55 Medium' !important;
@fontFamilyNormal: 'Core Rhino 35' !important;
@fontFamilySlim: 'Core Rhino 25' !important;

@font-face {
  font-family: 'Core Rhino 35';
  src: url('fonts/CoreRhino35Light.eot');
  src: local('Core Rhino 35 Light'), local('CoreRhino35Light'),
  url('fonts/CoreRhino35Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino35Light.woff2') format('woff2'),
  url('fonts/CoreRhino35Light.woff') format('woff'),
  url('fonts/CoreRhino35Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Core Rhino 25';
  src: url('fonts/CoreRhino25Thin-Italic.eot');
  src: local('Core Rhino 25 Thin Italic'), local('CoreRhino25Thin-Italic'),
  url('fonts/CoreRhino25Thin-Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino25Thin-Italic.woff2') format('woff2'),
  url('fonts/CoreRhino25Thin-Italic.woff') format('woff'),
  url('fonts/CoreRhino25Thin-Italic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Core Rhino 75';
  src: url('fonts/CoreRhino75Heavy.eot');
  src: local('Core Rhino 75 Heavy'), local('CoreRhino75Heavy'),
  url('fonts/CoreRhino75Heavy.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino75Heavy.woff2') format('woff2'),
  url('fonts/CoreRhino75Heavy.woff') format('woff'),
  url('fonts/CoreRhino75Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Core Rhino 85';
  src: url('fonts/CoreRhino85Black-Italic.eot');
  src: local('Core Rhino 85 Black Italic'), local('CoreRhino85Black-Italic'),
  url('fonts/CoreRhino85Black-Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino85Black-Italic.woff2') format('woff2'),
  url('fonts/CoreRhino85Black-Italic.woff') format('woff'),
  url('fonts/CoreRhino85Black-Italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Core Rhino 45';
  src: url('fonts/CoreRhino45Regular-Italic.eot');
  src: local('Core Rhino 45 Regular Italic'), local('CoreRhino45Regular-Italic'),
  url('fonts/CoreRhino45Regular-Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino45Regular-Italic.woff2') format('woff2'),
  url('fonts/CoreRhino45Regular-Italic.woff') format('woff'),
  url('fonts/CoreRhino45Regular-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Core Rhino 55';
  src: url('fonts/CoreRhino55Medium-Italic.eot');
  src: local('Core Rhino 55 Medium Italic'), local('CoreRhino55Medium-Italic'),
  url('fonts/CoreRhino55Medium-Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino55Medium-Italic.woff2') format('woff2'),
  url('fonts/CoreRhino55Medium-Italic.woff') format('woff'),
  url('fonts/CoreRhino55Medium-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Core Rhino 85';
  src: url('fonts/CoreRhino85Black.eot');
  src: local('Core Rhino 85 Black'), local('CoreRhino85Black'),
  url('fonts/CoreRhino85Black.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino85Black.woff2') format('woff2'),
  url('fonts/CoreRhino85Black.woff') format('woff'),
  url('fonts/CoreRhino85Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Core Rhino 45';
  src: url('fonts/CoreRhino45Regular.eot');
  src: local('Core Rhino 45 Regular'), local('CoreRhino45Regular'),
  url('fonts/CoreRhino45Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino45Regular.woff2') format('woff2'),
  url('fonts/CoreRhino45Regular.woff') format('woff'),
  url('fonts/CoreRhino45Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Core Rhino 55';
  src: url('fonts/CoreRhino55Medium.eot');
  src: local('Core Rhino 55 Medium'), local('CoreRhino55Medium'),
  url('fonts/CoreRhino55Medium.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino55Medium.woff2') format('woff2'),
  url('fonts/CoreRhino55Medium.woff') format('woff'),
  url('fonts/CoreRhino55Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Core Rhino 25';
  src: url('fonts/CoreRhino25Thin.eot');
  src: local('Core Rhino 25 Thin'), local('CoreRhino25Thin'),
  url('fonts/CoreRhino25Thin.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino25Thin.woff2') format('woff2'),
  url('fonts/CoreRhino25Thin.woff') format('woff'),
  url('fonts/CoreRhino25Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Core Rhino 35';
  src: url('fonts/CoreRhino35Light-Italic.eot');
  src: local('Core Rhino 35 Light Italic'), local('CoreRhino35Light-Italic'),
  url('fonts/CoreRhino35Light-Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino35Light-Italic.woff2') format('woff2'),
  url('fonts/CoreRhino35Light-Italic.woff') format('woff'),
  url('fonts/CoreRhino35Light-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Core Rhino 75';
  src: url('fonts/CoreRhino75Heavy-Italic.eot');
  src: local('Core Rhino 75 Heavy Italic'), local('CoreRhino75Heavy-Italic'),
  url('fonts/CoreRhino75Heavy-Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino75Heavy-Italic.woff2') format('woff2'),
  url('fonts/CoreRhino75Heavy-Italic.woff') format('woff'),
  url('fonts/CoreRhino75Heavy-Italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Core Rhino 65';
  src: url('fonts/CoreRhino65Bold.eot');
  src: local('Core Rhino 65 Bold'), local('CoreRhino65Bold'),
  url('fonts/CoreRhino65Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino65Bold.woff2') format('woff2'),
  url('fonts/CoreRhino65Bold.woff') format('woff'),
  url('fonts/CoreRhino65Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Core Rhino 65';
  src: url('fonts/CoreRhino65Bold-Italic.eot');
  src: local('Core Rhino 65 Bold Italic'), local('CoreRhino65Bold-Italic'),
  url('fonts/CoreRhino65Bold-Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/CoreRhino65Bold-Italic.woff2') format('woff2'),
  url('fonts/CoreRhino65Bold-Italic.woff') format('woff'),
  url('fonts/CoreRhino65Bold-Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Core Rhino 55 Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Core Rhino 55 Medium'), url('fonts/CoreRhino55Medium.woff') format('woff');
}

@font-face {
  font-family: 'Core Rhino 55 Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Core Rhino 55 Medium Italic'), url('fonts/CoreRhino55Medium-Italic.woff') format('woff');
}

body {
  font-family: @fontFamily;
  position: relative;
}