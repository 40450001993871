@carouselBorder: 1px solid #ededed;
.report-page-carousel,
.home-page-carousel {
  min-height: 360px;
  margin-top: 1.5em;
  position: relative;

  .swiper-container {
    padding-bottom: 50px;
  }

  .carousel-item {
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    background: #fff;
    border-radius: 30px;
    border: @carouselBorder;

    &:hover {
      box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.1);
    }


    img {
      width: 13em;
      display: block;
      margin: 0 auto;
    }
  }

  .carousel-item-footer {
    border-top: @carouselBorder;
    padding-top: 0;

    .info {
      padding-top: 15px;
      margin-bottom: -10px;

      b {
        display: block;
        font-size: @fontSize+0.5;
        text-align: center;
      }

      p {
        margin-bottom: -1px;
        text-align: center;
      }

      @media @md {
        b {
          text-align: right;
        }

        p {
          text-align: left;
        }
      }
      @media @lg {
        b {
          text-align: center;
        }

        p {
          text-align: center;
        }
      }
      @media @xs {
        p {
          padding-top: 5px;
        }
      }

    }

    .ant-btn-ghost,
    .ant-btn-primary {
      margin-top: 0px;
      @media @xs {
        margin-top: 20px;
        padding: 23px 0px;
        line-height: 0;
      }
    }

    .ant-btn-ghost {
      @media @xs {
        margin-top: 25px;
        padding: 23px 45px;
        line-height: 0;
      }
    }


    .ant-btn-primary {
      background: @yellowColor;
      border-color: @yellowColor;
      @media @xl {
        padding: 23px 45px;
        line-height: 0;
      }

      &:hover {
        .yellowShadow;
      }
    }

    .ant-btn-ghost {
      background: #FFFFFF;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
      border-radius: 40px;
      border-color: transparent !important;

      position: relative;


      .anticon-heart.o,
      .anticon-heart.f {
        position: absolute;
        left: -15px;
        top: 2px;
      }

      .carousel-ghost-btn-c {
        position: relative;
        display: inline-block;
        width: 120px;
        padding: 10px;
      }

      &:visited,
      &:active,
      &:hover {
        color: #555;

        .anticon-heart.f {
          display: inline-block !important;

          path {
            fill: red;
          }
        }

        .anticon-heart.o {
          display: none;
        }
      }

      @media @xs {
        padding: 15px 25px 32px 25px;
        line-height: 0;
        margin-bottom: 20px;
      }
      @media @md {
        margin-bottom: 0;
      }
      @media @lg {
        margin-bottom: 0;
      }
      @media @xl {
        padding: 15px 25px 32px 25px;
        line-height: 0;
      }

    }
  }

  .navigation-container {

    position: relative;
    height: 20px;

    .swiper-button-prev,
    .swiper-button-next {
      opacity: 0.3;
      background-image: url(~"/img/arrow.svg");
      font-size: 0 !important;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: 10px 6px;
      border: 1px solid #999;
      border-radius: 100px;
      right: -35px !important;
      top: 57%;

      &:after {
        display: none;
      }

      &:hover {
        opacity: 1;
      }

      &.swiper-button-disabled {
        opacity: 0.1;
      }
    }

    .swiper-button-prev {
      left: -35px !important;
      transform: rotate(180deg);
    }

    .navigation-block {
      position: relative;
      width: max-content;
      display: block;
      margin: 0 auto;
      padding: 33px 0;
    }

    .swiper-pagination {
      position: relative;
      width: max-content;
      display: block;
      margin: 0 auto;
    }

    .swiper-button-prev {
      left: -70%;
    }

    .swiper-button-next {
      right: -70%;
    }

    .swiper-pagination-bullet {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 0 7px;
      background: #B6B1B1;
    }

    .swiper-pagination-bullet-active {
      background: @yellowColor;
    }
  }

  @media @xs {
    h2 {
      font-size: @fontSize + 0.6;
    }
  }

  @media @sm {
    h2 {
      font-size: @fontSize + 0.93;
    }
  }

  @media @md {

  }

  @media @lg {

  }

  @media @xl {

  }

  @media @xxl {

  }

  &.home-page-carousel .swiper-container {
    &:after {
      @media @xl {
        background-position: -413px -200px;
        background-size: 34em !important;
        background-image: url(~"/img/hand-waves.svg.png");
        background-repeat: no-repeat;
      }
      @media @xxl {
        background-image: url(~"/img/hand-waves.svg.png");
        background-repeat: no-repeat;
        background-position: -508px -349px;
        background-size: 44em !important;
      }
    }
  }
}

.carousel-item {
  .round-img {
    margin: 20px auto;
  }
}

.custom-swiper-container {


  .swiper-container {
    overflow: visible;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 8000px;
      right: -8020px;
      top: 0;
      background: #fff;
      z-index: 1;
    }

    &:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 8000px;
      left: -8020px;
      top: 0;
      background: #fff;
      z-index: 2;
    }
  }
}

.carousel-item {
  width: 100%;
}

.report-page-carousel {

  .swiper-slide {
    text-align: center;
    background: #fff;
    height: calc((100% - 30px) / 2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }


  .carousel-item {
    h3 {
      font-size: @fontSize + 0.5;
    }
  }
}