.whp-site-container {
  .text-error{
    color: rgba(255, 0, 0, 0.75);
  }

  .text-warning{
    color: rgba(204, 124, 0, 0.75);
  }
  //.fadeOut {
  //  opacity: 0;
  //  transition: opacity 0s;
  //
  //}
  //
  //.fadeIn {
  //  opacity: 1;
  //  transition: opacity 0.5s;
  //}

  p {
    margin-bottom: 0;
  }

  @import "./styles/params";
  @import "./font";
  @import "./styles/header";
  @import "./styles/carousel";
  @import "./styles/report";
  @import "./styles/news";
  @import "./styles/partners";
  @import "./styles/footer";
  @import "./styles/tabs";
  @import "./styles/tabs-custopm";
  @import "./styles/about";
  @import "./styles/table-info-widget";
  @import "./styles/projects";
  @import "./styles/want-to-help";
  @import "./styles/our-wards-page";

  .swiper-button-next, .swiper-button-prev {
    cursor: pointer;
    color: var(--swiper-navigation-color, #5b5b5b);
  }

  :root {
    --font-size: 1em;
    --layoutbg: red;
  }

  body {
    margin: 0 auto;
    position: relative;
    //background: linear-gradient(#fff, #fefefe);
    overflow-x: hidden;
  }

  .markup-layer {
    height: 100rem;
    position: absolute;
    left: 0;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: @xxlWidth;
  }

  .page-container {
    max-width: @xxlWidth;
    background-size: 0 !important;
    background-repeat: no-repeat;
    margin: 0 auto 0 auto;
    overflow: hidden;
    padding-top: 40px;
  }

  .image-contain.gray-bg {
    min-height: 174px;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 16px;
  }

  .content-container {
    width: 90%;
    margin: 0 5%;

    &.min-h {
      min-height: 450px;
    }
  }

  @media @xs {
    .content-container {
      width: 88%;
      margin: 0 6%;
    }

    .page-title-container {
      h1 {
        font-size: @fontSize+0.9;
      }

      p {
        font-size: @fontSize - 0.1;
        padding-bottom: 5px;
      }

      span {
        font-size: @fontSize - 0.15;
      }

      text-align: center;
    }

    .logo-image {
      width: 150px !important;
      position: relative;
      top: 9px;
      left: 5px;
    }
  }

  @media @xs {
    .logo-image {
      min-height: 1px;
    }

    .page-title-container {
      font-size: @fontSize + 0.2;

      h1 {
        font-size: @fontSize+0.9;
        padding-top: 1.2em;
        line-height: @fontSize + 0.1;
      }

      p {
        font-size: @fontSize + 0.1;
        line-height: @fontSize + 0.2;
      }
    }

  }

  @media @sm {
    .logo-image {
      min-height: 85px;
    }

    .page-title-container {
      text-align: left;

      h1 {
        font-size: @fontSize+1.86;
        padding-top: 1.1em;
        margin: 0 0 0.43em 0;
        line-height: 1em;
      }

      p {
        font-size: @fontSize+0.291;
        line-height: @fontSize+0.3;
      }

      span {
        font-size: @fontSize;
      }
    }

    .logo-image {
      width: 200px !important;
      position: relative;
      top: 0;
      left: 0;
    }

    .page-container {
      background-image: none;
    }
  }

  @media @md {
    .content-container {
    }
  }

  @media @lg {
    .content-container {

    }

    .page-container {
      background-position: 100% 220px;
      background-size: 35em !important;
    }
  }

  @media @xxl {
    .content-container {
      width: 84%;
      margin: 0 8%;
    }

    .page-container {
      background-position: 100% 0;
      background-size: 44em !important;
    }
  }

  .gray-block {
    background: #F4F6F7;
    border-radius: 30px;
    padding: 20px 20px;
    font-size: @fontSize+0.5;
  }

  .yellowShadow {
    box-shadow: 5px 5px 15px rgba(255, 139, 0, 0.5) !important;
  }

  .yellowBt {
    color: #FFFFFF;
    background: @yellowColor;
    border-color: @yellowColor;
    border-radius: 30px;
    //padding: 15px 30px 35px 30px;
    padding: 10px 20px;
    font-weight: bold;
    height: auto;


    &:focus,
    &:active,
    &:visited,
    &:hover {
      color: #FFFFFF;
      background: @yellowColor;
      border-color: @yellowColor;
    }

    &:focus,
    &:hover {
      .yellowShadow;
    }

    &:disabled {
      box-shadow: none !important;
      background: inherit !important;
    }
  }

  .image-placeholder {
    width: 100%;
    height: 100%;

    .anticon-spin {
      width: 20%;
      color: #b9b9b9;
      margin: 10% auto;
      display: block;
      height: 100%;
      font-size: 3em;
    }
  }

  .dashedBtn:hover,
  .dashedBtn:active,
  .dashedBtn:visited,
  .dashedBtn:focus,
  .dashedBtn {
    border: none;
    color: @colorMuted;
    border-bottom: 1px dashed @colorMuted;
    background: none;
    padding: 0;
    margin: 10px 0;
    font-weight: bold;
    font-size: @fontSize + 0.01;
  }

  h1 {
    @media @xs {
      font-size: @fontSize + 0.6 !important;
    }
    @media @sm {
      font-size: @fontSize + 0.8 !important;
    }
    @media @md {
      font-size: @fontSize + 1 !important;
    }
    @media @lg {
      font-size: @fontSize + 1.5 !important;
    }
    @media @xl {
      font-size: @fontSize + 2 !important;
    }
  }

  .logo-container-w {
    position: relative;
    text-align: left;
    width: max-content;
    margin: 0 auto;

    .img,
    .text {
      display: inline-block;
    }

    .img {
      img {
        @media @xs {
          width: 39px;
        }
        @media @sm {
          width: 53px;
        }
        @media @md {
          width: 55px;
        }
        @media @lg {
          width: 84px;
        }
      }
    }

    .text {
      color: #333;
      position: relative;
      top: 0.9em;

      span {
        line-height: 0;
        display: block;
        position: relative;
        padding-bottom: 25px;
        top: 0.5em;
        left: 10px;
        @additionalSize: -0.5;
        @additionalSpace: -5;
        @media @xs {
          font-size: @fontSize + (0.8 + @additionalSize);
          padding-bottom: 20px  + @additionalSpace;
          top: 0.5em ;
        }
        @media @sm {
          font-size: @fontSize + (0.8 + @additionalSize);
          padding-bottom: 25px + @additionalSpace;
          top: 0.5em;
        }
        @media @md {
          font-size: @fontSize + (1 + @additionalSize);
          padding-bottom: 25px + @additionalSpace;
          top: 0.5em;
        }
        @media @lg {
          font-size: @fontSize + (1 + @additionalSize);
          padding-bottom: 30px + @additionalSpace;
          top: 0.65em;
        }
      }
    }
  }

  ul.gray-dots {
    list-style: none;

    li {
      margin-top: 30px;
      position: relative;
      color: #262D42;
      display: block;
      line-height: 30px;
      text-align: left;


      &:before {
        content: "\2022";
        color: #8992AB;
        font-weight: bold;
        display: inline-block;
        width: 20px;
        margin-left: 5px;
        font-size: 3em;
        position: absolute;
        top: 0;
        left: -50px;
      }


      .font-slim;
      .fs-lg;
      color: #262D42;
    }
  }

  .grayBt:focus,
  .grayBt:active,
  .grayBt:hover,
  .grayBt {
    .yellowBt;
    background-color: transparent !important;
    border: 2px solid #EAE8F1 !important;
    color: #8992AB !important;
    display: inline-block;
    .font-slim;
    line-height: revert !important;

    &:active,
    &:focus,
    &:hover {
      box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1) !important;
    }


    @media @xs {
      padding: 10px 20px 35px 20px !important;
    }
    @media @sm {
      padding: 10px 30px 35px 30px !important;
    }
  }

  .ant-modal {
    .ant-modal-content {
      padding: 20px;
      position: relative;
      border-radius: 30px !important;
      box-shadow: 50px 38px 102.371px rgba(120, 118, 148, 0.14);

      .close-modal-x {
        position: relative;
        top: 0;
        right: 5px;
        color: #333;
        .font-slim;
        font-size: @fontSize + 1;
      }
    }

    position: relative;

    .modal-separator {
      border-top: 1px solid #ececec;
      width: calc(100% + 88px);
      position: relative;
      left: -44px;
    }

    @media @xs {
      width: 90% !important;
    }

    @media @sm {
      width: 80% !important;
    }

    @media @md {
      width: 85% !important;
    }

    @media @lg {
      width: 70% !important;
    }

    @media @xl {
      width: 1000px !important;

    }
  }

  .ant-modal-mask {
    background: #8992ABBD;
  }

  .ant-scrolling-effect {
    scroll-margin: 0;
  }

  .round-img {
    width: 150px;
    height: 150px;
    border-radius: 1000px;
    overflow: hidden;
    border: 5px solid #FFFFFF;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2);
    display: block;
    margin: 0 auto;
    background-size: cover !important;
    background-position: center !important;
  }

  .main-carousel-modal {
    .info {
      line-height: 25px;
    }

    @media @xs {
      .round-img {
        margin-bottom: 30px;
      }

      .info {
        text-align: center;
        margin-bottom: 30px;
      }
    }
    @media @lg {
      .round-img {
        margin-left: 0;
        margin-bottom: 10px;
      }

      .info {
        text-align: inherit;
        margin-bottom: inherit;
      }
    }

    .yellowBt {
      padding: 15px 20px;
    }
  }


  .yellowLink {
    color: @yellowColor;
    text-decoration: underline;
  }

  button[disabled]:visited,
  button[disabled]:active,
  button[disabled]:focus,
  button[disabled]:hover,
  button[disabled] {
    color: #dedede;
    border-color: #dedede;
  }
}

.ant-modal-body,
.home-page-carousel {

  .rub-sign-svg {
    width: 14px !important;
  }
}

a.ant-btn.grayBt,
a.ant-btn.grayBt:focus,
a.ant-btn.grayBt:active,
a.ant-btn.grayBt:hover,
a.ant-btn.yellowBt{
  padding: 15px 30px!important;
}
