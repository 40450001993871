.projects-view-container {
  @media @xs {
    background-size: 0 !important;
  }
  @media @lg {
    background-size: 350px !important;
  }
}

.projects-page {


  .item-container {

    .content {
      display: block;
      border: 1px solid rgba(137, 146, 171, 0.2);
      border-radius: 35px;
      margin-bottom: 40px;
      background: #fff;

      &:hover {
        box-shadow: 4px 4px 55px rgba(0, 0, 0, 0.1);
      }

      padding: 40px;

      label {
        cursor: pointer;
        display: inline-block;
        background: rgba(39, 174, 96, 0.1);
        border-radius: 8px;
        padding: 5px 15px;
        margin-bottom: 10px;
        font-size: @fontSize - 0.01;
        color: #27AE60;

        &.type-2 {
          background: rgba(45, 156, 219, 0.1);
          color: #2D9CDB;
        }
      }

      .description {
        color: @colorMuted;
        .font-slim;
        line-height: @fontSize + 0.3;
      }

      @media @xs {
        //width: 288px;
        min-height: 224px;
      }

      @media @sm {
        //width: 536px;
        min-height: 208px;
      }

      @media @md {
        //width: 728px;
        min-height: 191px;
      }

      @media @lg {
        //width: 451px;
        height: 336px;
      }

      @media @xl {
        //width: 440px;
        padding-top: 15%;
        height: 336px;
      }

      @media @xxl {
        //width: 486px;
        height: 330px;
      }

    }

  }

  .view {
    .go-back-link {
      margin-top: 25px;
      position: relative;
    }

      @media @xs {
        .go-back-link{
          margin: 25px auto 0 auto;
          display: block;
        }
      }

    @media @sm {
      .go-back-link{
        margin: 25px auto 0 0;
      }
    }


    .page-title-container {
      h1 {
        padding-top: 0.5em;
      }
    }

    .white-container {
      background: #fff;
      box-shadow: 50px 38px 102.371px rgba(120, 118, 148, 0.14);
      border-radius: 30px;
      padding: 48px;
      border: 1px solid #f6f6f6;
      .font-slim;
      font-size: @fontSize + 0.14;
    }

    ul {
      .font-slim;
      font-size: @fontSize - 0.01;

      li {
        margin-top: 25px;
        list-style: none;
        position: relative;

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          border-radius: 100px;
          width: 8px;
          height: 8px;
          background: @yellowColor;
          left: -20px;
          top: 7px;
        }
      }
    }
  }
}