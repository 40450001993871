@yellowColor: #ff8b00;
@colorMuted: #767880;


@fontSize: 1em;
@h2DefaultSize: @fontSize + 1;

@xsWidth: 256px;
@smWidth: 576px;
@mdWidth: 768px;
@lgWidth: 992px;
@xlWidth: 1200px;
@xxlWidth: 1600px;
//@xxlWidth: 1800px;

@xs: ~"(min-width: @{xsWidth})";
@sm: ~"(min-width: @{smWidth})";
@md: ~"(min-width: @{mdWidth})";
@lg: ~"(min-width: @{lgWidth})";
@xl: ~"(min-width: @{xlWidth})";
@xxl: ~"(min-width: @{xxlWidth})";
@domain: '//';
:root {
  --domain: '//';
}

.color-yellow {
  color: @yellowColor;
}

.color-white {
  color: white !important;
}

.color-black {
  color: black !important;
}

.pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.block-inline {
  display: inline-block;
}

.opacity {
  opacity: 0;
}

.hidden {
  display: none !important;
}

.p0 {
  padding: 0;
}

.text-muted {
  color: @colorMuted;
}

.tac {
  text-align: center !important;

  * {
    text-align: center !important;
  }
}

.tal {
  text-align: left;
}

.sm-tal {
  @media @sm {
    text-align: left !important;
  }
}

.tar {
  text-align: right;
}

.cb {
  //display: inline-flex;
  //margin: 0 auto;
}

.font-default {
  font-family: @fontFamily;
  font-size: @fontSize + 0.5;
}

.font-md {
  font-family: @fontFamilyNormal;
  font-size: @fontSize + 0.3;
}

.font-slim {
  font-family: @fontFamilySlim;
  font-size: @fontSize + 0.2;
}

.oh {
  overflow: hidden;
}

.ov {
  overflow: visible;
}

oxv {
  overflow-x: visible;
}

oxh {
  overflow-x: hidden;
}

oyv {
  overflow-y: visible;
}

oyh {
  overflow-y: hidden;
}

.fs-xs {
  font-size: @fontSize - 0.15 !important;
}

.fs-sm {
  font-size: @fontSize - 0.01 !important;
}

.fs-md {
  font-size: @fontSize + 0.15 !important;
}

.fs-lg {
  font-size: @fontSize + 0.20 !important;
}

.fs-xl {
  font-size: @fontSize + 0.80 !important;
}

.ttu {
  text-transform: uppercase;
}

.ttl {
  text-transform: lowercase;
}
