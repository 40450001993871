.home-page-partners {
  text-align: center;

  h2{
    font-size: @h2DefaultSize;
    margin: 50px 0;
  }

  .item{
    margin-bottom: 30px;


    img{
      max-width: 130px;
    }
  }

  @media @xs {

  }

  @media @sm {

  }

  @media @md {

  }

  @media @lg {

  }

  @media @xl {

  }

  @media @xxl {

  }
}