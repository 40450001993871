.home-page-report-video {
  position: relative;

  .play-btn {
    font-size: 70px;
    position: absolute;
    top: 115px;
    border-radius: 100px;
    color: #ff8b00;
    z-index: 2;
    display: block;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    background-color: rgba(0, 0, 0, 0.53);
    background-image: url(~"/img/play.png");
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 20px 18px;
    cursor: pointer;
  }

  &.cover:after {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    content: '';
    display: block;
    //background: white;
    border-radius: 30px;
    height: 300px;
  }

  .vid-container {
    z-index: 3;
  }

  video {
    width: 100%;
    height: 300px;
    border-radius: 30px;
    border: 1px solid #ededed;

    &.play {
      border: 1px solid transparent;
    }
  }
}

.home-page-report {
  margin-top: 50px;
  text-align: center;

  .item {
    h3 {
      font-size: @fontSize+0.9;
      font-weight: bold;
      margin-bottom: 0;
    }

    p {
      .font-slim;
      font-size: 1em;
    }
  }
}

.report-page {


  .ant-tabs-content {
    padding: 15px 0px 40px 0;
  }

  .page-title-container h1 {
    margin: 0 0 1em 0;
  }

  .ant-card-bordered {
    border: 1px solid #f0f0f0;
    border-radius: 25px;
    padding: 10px 10px;
    @media @sm {
      padding: 10px 50px;
    }
    margin-bottom: 25px;

    &:hover {
      box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.1);
    }

    h3 {
      margin-top: 0;
      @media @xs {
        font-size: @fontSize + .2;
      }
      @media @sm {
        font-size: @fontSize + .7;
      }
    }

    .info-link {
      padding: 0;
      color: #8992AB;
      font-size: 0.9em;
      border-bottom: 1px dashed #8992AB;
    }
  }

  .ant-tabs.ant-tabs-top.round-tabs {
    position: relative;
  }

  .ant-tabs-tabpane {
    &.ant-tabs-tabpane-active {

    }
  }

}
