.admin-content-container > ant-row,
.admin-content-container,
.admin-content-container body {
    text-decoration: none;
    background: rgba(239, 239, 239, 0.9) !important;
    min-height: 100vh;
}

.admin-content-container .rename-file {
    position: absolute;
    width: 100%;
    display: block;
    left: -1px;
    right: 0;
    top: -1px;
    bottom: -1px;
    background: #eee;
    padding: 7px 25px 10px 7px;
    border: 1px solid;
}
.admin-content-container .ck-placeholder {
    opacity: 0.4;
}

.admin-content-container .ck.ck-button {
    cursor: pointer;
}

.admin-content-container .ck.ck-toolbar {
    background: rgba(215, 215, 215, 0.31);
}

.admin-content-container .ant-picker {
    width: 100%;
}

.admin-content-container .ant-picker,
.admin-content-container .ck.ck-toolbar,
.admin-content-container .ck.ck-content {
    border: 2px solid rgba(2, 93, 138, 0.37) !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 2px solid rgba(2, 93, 138, 0.37) !important;
}

.admin-content-container .ant-input-affix-wrapper {
    border: 2px solid rgba(2, 93, 138, 0.37);
    overflow: hidden;
}

.admin-content-container .ant-form-item-has-error:hover .ant-input-affix-wrapper,
.admin-content-container .ant-form-item-has-error .ant-input-affix-wrapper {
    border-color: #ffb7b7;
}

.admin-content-container .ck.ck-content {
    border-top: none !important;
}

.admin-content-container .ant-table-thead > tr > th {
    padding: 25px 16px;
}

.admin-content-container .ant-table-tbody > tr > td,
.admin-content-container .ant-table tfoot > tr > th,
.admin-content-container .ant-table tfoot > tr > td {
    padding: 20px 16px;
}

/*table*/
.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #004d95a8;
    border-bottom: 2px solid #004d95a8;
}

.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    font-size: 0.9em;
    text-transform: uppercase;
    padding: 15px 20px;
    font-weight: bold;
}

.admin-content-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:nth-child(2) {
    margin-left: 0;
}

.admin-content-container .main-menu-content {
    background: #fff !important;
}

.admin-content-container .ant-menu-horizontal {
    background: transparent;
    border-bottom: transparent;
}

.admin-content-container .content-block-gray,
.admin-content-container .ant-card,
.admin-content-container .main-menu-content,
.admin-content-container .site-base-content-container,
.admin-content-container .ant-table-content {
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}

.admin-content-container .ant-table-thead > tr > th {
    /*background: rgb(249, 249, 249) !important;*/
    border-bottom: 1px solid #dedede !important; /*border*/

    background: #408dbb21 !important;
}

.admin-content-container .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #408dbb0a !important;
}

.admin-content-container .ant-table-expanded-row-fixed {
    width: 100% !important;
}

.admin-content-container .ant-table-expanded-row-fixed:after {
    border: none !important;
}

.admin-content-container .ant-table.ant-table-bordered > .ant-table-container {
    border: 1px solid #dedede !important; /*border*/
}

.admin-content-container .ant-table .table-image {
    width: 64px;
    border: 1px solid #ececec;
}

.admin-content-container .ant-table .ant-image-img {
    width: auto !important;
}

/*table end*/

.admin-content-container .ant-col.ant-form-item-label {
    width: 100%;
}

.admin-content-container .file-form-view {
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #eee;
}

/*content*/
.admin-content-container .content-block-gray {
    background: rgb(255, 255, 255);
    padding: 15px 20px;
    min-height: 58px;
    border: 1px solid #dedede !important; /*border*/
}

.admin-content-container .site-page-title {
    margin: 0;
    padding: 5px 0px;
    font-size: 1.1em;
    font-weight: bold;
}

.admin-content-container .site-base-content-container {
    /*min-height: 80vh;*/
}

/*content end*/

.admin-content-container .site-layout-background {
    padding: 0 !important;
    z-index: 1;
}

.admin-content-container .application-header-title {
    margin: 10px 0;
    position: relative;
    min-height: 66px;
}

.admin-content-container hr {
    border: 0.01em solid #eaeaea;
}

.admin-content-container .not-found-error-page {
    color: red;
}

.admin-content-container .header-actions-container {
    text-align: right;
    padding: 2px 0 0 0;
}

.admin-content-container .remove-recipe-ingredient-bt.ant-btn-ghost,
.admin-content-container .header-action-btn {
    margin-right: 10px;
    border: none !important;
}

.admin-content-container .header-action-btn .anticon {
    font-size: 1.3em;
}

.admin-content-container .header-action-btn.create {
    color: green;
}

.admin-content-container .header-action-btn.view {
    color: darkblue;
}

.admin-content-container .header-action-btn.update {
    color: coral;
}

.admin-content-container .header-action-btn.delete {
    color: orangered;
}

.admin-content-container .header-action-btn.index {
    color: #0367a4;
}

.admin-content-container .ant-upload-list-item-name {
    color: #006efb;
    cursor: pointer;
}

.admin-content-container .ant-form-item-label label {
    font-weight: bold
}

.admin-content-container .layout-loader {
    top: 0;
    font-size: 0 !important;
    line-height: 0 !important;
    position: fixed !important;
    left: 0;
}

.admin-content-container .layout-loader .ant-progress-inner,
.admin-content-container .layout-loader .ant-progress-bg {
    height: 2px !important;
    border-radius: 0 !important;
}

.admin-content-container .btn_select_addon {
    position: relative !important;
    bottom: -40px !important;
    float: right !important;
}

.admin-content-container .ant-btn-icon-only.ant-btn-sm > * {
    font-size: 18px;
}

.admin-content-container .ant-table-cell {
    text-align: center;
}

.admin-content-container .ant-upload.ant-upload-select-picture-card,
.admin-content-container .ant-upload-list-picture-card-container {
    width: auto !important;
    height: auto !important;
}

.admin-content-container .ant-upload-list-picture-card-container > div > div > div {
    background: transparent !important;
}

.admin-content-container .ant-upload-select {
    display: block;
    padding: 20px;
    max-width: 250px;
}
.admin-content-container .ant-menu-horizontal > .ant-menu-item::after,
.admin-content-container .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: 2px solid transparent !important;
}