.blog-page,
.home-page-info,
.home-page-news {
  margin-top: 6em;

  &.blog-page {
    margin-top: 0;
  }

  text-align: center;

  &.home-page-info {
    margin-top: 4em !important;
  }

  .ant-card {
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 30px;

    &:hover {
      box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
    }

    img {
      border-radius: 15px;
    }

    .ant-card-body {
      padding: 16px;
    }

    .item {
      text-align: left;

      h3 {
        font-size: @fontSize+0.5;
        line-height: 1.1em;
      }

      p {
        font-size: @fontSize - 0.05;
        color: @colorMuted;
      }

      .date {
        margin: 15px 0 20px 0;
        background: #F3F2F7;
        padding: 5px 13px;
        font-size: 0.9em;
        font-weight: 100;
        border-radius: 10px;
        width: max-content;
      }
    }
  }

  .header {
    margin-bottom: 20px;

    h2 {
      font-size: @h2DefaultSize;
    }

    .left {
      p {
        color: #393939;
        margin-top: 13px;
      }
    }
  }

  @media @xs {
    .image-contain {
      img,
      & > div {
        height: auto;
        max-height: 390px;
        background-size: cover !important;
      }

    }
  }

  @media @sm {
    .image-contain {
      img,
      & > div {
        height: 270px !important;
        //background-size: 100% 100% !important;
      }
    }

    .header {
      .left {
        text-align: left;
      }

      .right {
        text-align: right;
      }
    }
  }

  @media @md {
    .image-contain {
      img,
      & > div {
        height: auto !important;
        max-height: 300px;
        background-size: cover !important;
      }
    }
  }

  @media @lg {
    .image-contain {
      img,
      & > div {
        height: 270px !important;
        background-size: cover !important;
      }
    }
  }

  @media @xl {

  }

  @media @xxl {

  }

  .news-id-1 {
    //.image-contain > div {
    //  background-size: cover !important;
    //}
  }
}

.ant-image-mask {
  width: 300%;
  opacity: 0;
}

.news-view-page {
  .go-back-link {
    border: none;
    margin-bottom: 30px;
  }

  .bottom-navigation-bootons {

    button span,
    b {
      font-size: @fontSize - 0.2 !important;
      @media @md {
        font-size: @fontSize !important;
      }
    }

    b {
      display: block;
    }
  }

  .date {
    background: #F3F2F7;
    padding: 1px 13px;
    font-weight: 100;
    border-radius: 10px;
    width: max-content;
    margin: -10px auto 25px auto;
    .font-slim;
    font-size: @fontSize - 0.2 !important;
  }

  .page-title-container {
    position: relative;

    h1 {
      position: relative;
      top: -40px;
      margin-bottom: 0;
      z-index: -1;
    }

    p {
      .tac;

      span {
        font-size: @fontSize - 0.1 !important;
      }
    }
  }

  .go-back-link {
    z-index: 1;
  }

  .gray-block {
    font-family: 'Core Rhino 35';
    padding: 30px 45px;
    .tac;
  }

  img {
    border-radius: 30px;
    width: 100%;
  }

  .swiper-button-disabled {
    display: none;
  }

  .news-page-carousel {
    //box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    //border-radius: 20px;
  }

  &.news-id-1 {
    img {
      height: 400px;
    }

    .swiper-slide > div {
      height: 400px;
      background-size: cover !important;
      @media @xs {
        height: 330px;
      }
      @media @sm {
        height: 350px;
      }
      @media @md {
        height: 400px;
      }
    }
  }


  .carousel-news-container {
    border-radius: 30px;


    display: inline-block;
    max-width: 350px;
    margin-bottom: 20px;


    @media @sm {
      max-width: 400px;
      margin-left: 20px !important;
      float: right;
    }

    &.carousel-news-container,
    img {

      max-width: 400px;
      max-height: 350px;

      @media @xs {
        margin: 0 auto 70px auto;
        display: block;
        .swiper-slide {
          & > div {
            background-size: cover !important;
          }
        }
      }
      @media @sm {
        width: 350px;
      }
      @media @md {
        width: 400px;
      }
      @media @lg {
        width: 400px;
      }
      @media @xl {
        width: 400px;
      }
    }
  }
}
