.custom-tabs-widget-container {

  .custom-tabs-widget {
    overflow: visible !important;

    .ant-tabs-nav {
      display: none;
      line-height: 0;
    }
  }
}

.custom-tabs-widget-menu-container-block {
  position: relative;
  display: inline-block;
  border-radius: 100px;
  width: 100%;
  height: 60px;
  z-index: 2;


  .custom-tabs-widget-menu-container {


    padding: 6px 6px;

    width: 100%§!important;
    background: #F4F6F7;
    border-radius: 100px;

    display: table-cell;
    text-align: center;

    .btn-default {

      display: inline-block;
      width: max-content;
      box-shadow: none;
      border-radius: 100px;
      margin: 0 0;
      border: none;
      color: @colorMuted;
      background: transparent;
      font-size: @fontSize;
      padding: 5px 35px;

      &:visited,
      &:active,
      &:hover {
        color: #222;
        background: transparent;
      }

      &.active {
        color: #222;
        background: #fff;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      }

    }

    button {
      .btn-default
    }

    .defaultBg {
      background: none;
    }

    .mobile-menu-toggle {
      margin-bottom: 10px;
      position: relative;
      .pointer;
      @media @sm {
        display: none;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 15px;
        right: 10px;
        top: 10px;
        background-image: url(~"/img/arrow.svg");
        transform: rotate(88deg)
      }

      &.opened:after {
        transform: rotate(270deg)
      }
    }

    @media @xs {
      position: absolute;
      overflow: hidden;
      height: 50px;
      width: 100%;
      //padding-top: 45px;
      top: 0;
      right: 0;
      button {
        display: block;
        width: 97%;
        text-align: left;

        margin-bottom: 5px;
      }

      &.open {
        border-radius: 15px;
        height: auto;
        box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.1);

        &:after {
          transform: rotate(-88deg);
        }

      }

    }

    @media @sm {
      button {
        text-align: center;
      }

      &.open {
        padding-top: 55px;
        border-radius: 100px;
      }
    }
    @media @md {
      button {
        width: 30% !important;
        text-align: center;
      }
    }
    @media @xl {
      width: max-content !important;
      button {
        width: max-content !important;
      }
    }

    @media @sm {
      box-shadow: none !important;
      padding-top: 0;
      position: relative;
      border-radius: 100px;
      height: auto;
      left: auto;
      right: auto;

      &:after {
        display: none;
      }

      &.open {
        padding-top: 0;
      }

      button {
        display: inline-block;
        .btn-default;
        position: relative;
        top: 5px;
        right: auto;

        &.active {
          position: relative;
          top: 3px;
          left: auto;
        }
      }
    }


    @media @sm {
      &.about {
        border-radius: 20px;
      }
    }
    @media @xl {
      &.about {
        border-radius: 100px;
      }
    }
  }
}


@media @sm {
  .about-page {
    button {
      width: 33% !important;
      text-align: center;
    }
  }
}

@media @xl {
  .custom-tabs-widget-menu-container {
    display: block !important;
    margin: 0 auto !important;
  }
}
