.app-header {

  .toggleLangDropdown-container {
    z-index: 1;
    top: 50px;
    position: relative;
    display: none;

    &.open {
      display: block;
    }
  }

  .btn-link {
    display: block;
    font-size: 18px;
    color: #444;
    margin-top: 25px;

    &.active {
      span {
        position: relative;

        &:after {
          content: '';
          display: block;
          width: calc(100% + 8px);
          border-bottom: 2px solid;
          position: absolute;
          left: -2px;
          bottom: -5px;
        }
      }
    }
  }

  .menu-lg-container {
    //._about {
    //  left: -15px;
    //}
    //
    //._blog {
    //  left: -25px;
    //}
    //
    //._report {
    //  left: -25px;
    //}

    .burg-container {
      margin-top: 25px;
      opacity: 1;
      cursor: pointer;

      & > span {
        display: block;
        width: 2em;
        height: 4px;
        margin: 5px auto 5px 0;
        background-color: #7E8093;
        border-radius: 20px;

        &:nth-child(2) {
          width: 2.5em;
        }
      }

      &.active > span {
        background-color: #ff6c00;
      }
    }

    .eye-btn {
      font-size: 1.12em;
      color: #444;
      margin-top: 15px;
      border-radius: 25px;
      border: 2px solid rgb(222, 222, 222);
      background: #ffffff8a;
      position: absolute;
      padding: 0 25px;
      height: 41px;
      right: 60px;

      .eye-btn-ico {
        font-size: 1.3em;
        left: -8px;
        position: relative;
        top: 3px
      }
    }

    .eye-btn-small {
      font-size: 1.12em;
      color: #444;
      margin-top: 15px;
      border-radius: 25px;
      border: 2px solid rgb(222, 222, 222);
      background: #ffffff8a;
      position: absolute;
      padding: 0 19px;
      height: 40px;
      right: 60px;

      .eye-btn-ico-small {
        font-size: 1.5em;
        left: -11px;
        position: relative;
        top: 2px;
      }
    }

    .lang-switch-btn {
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      width: 40px;
      height: 40px;
      position: absolute;
      right: 9px;
      top: 20px;
      border-radius: 100px
    }
  }

  .switchLanguageContainer {
    z-index: 2;
    .lang-switch-btn.active {
      display: none;
    }

    .lang-switch-btn.ru {
      background-size: 120% 130% !important;
      border: 1px solid #eee !important;
      background-position: -1px -3px !important;
    }

    .lang-switch-btn.ru.en {
      background-size: 100% 100% !important;
      border: 1px solid #eee !important;
      background-position: center !important;
    }
  }

  .menu-md-container {
    border-top: 2px solid #dedede;
    border-bottom: 2px solid #dedede;
    padding: 5px 0 20px;

    .btn-link {
      font-size: 1.12em;
      color: #444;
      margin-top: 19px;
      margin-left: 0 !important;
      padding: 0 !important;
    }

    .ant-space {

      @media @md {
        .ant-space-item {
          margin-right: 35px !important;
        }

        .ant-space-item:last-child {
          margin-right: 0 !important;
        }
      }

      @media @lg {
        .ant-space-item {
          margin-right: 45px !important;
        }

        .ant-space-item:last-child {
          margin-right: 0 !important;
        }
      }

      @media @xl {
        .ant-space-item {
          margin-right: 80px !important;
        }

        .ant-space-item:last-child {
          margin-right: 0 !important;
        }
      }

      @media @xxl {
        .ant-space-item {
          margin-right: 100px !important;
        }

        .ant-space-item:last-child {
          margin-right: 0 !important;
        }
      }
    }

    .ant-col:last-child .btn-link {
      text-align: right !important;
    }

    .ant-col:first-child .btn-link {
      text-align: left !important;
    }
  }

  .burg-menu-links {
    border: 1px solid #ededed;
    width: 95%;
    margin: 0 auto;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 2.5%;
    background: white;
    border-radius: 15px;
    padding-bottom: 30px;
  }

  .logo-image {
    width: 200px;
    margin-left: 10px;
  }

  .header-logo {


    .logo-container-w {
      font-size: 2.7em;
    }

    .img {
      position: relative;
      top: -8px;
      left: -4px;
    }

    position: relative;
    top: -10px;
    left: -5px;

    @media @xs {
      position: relative;
      top: 6px;
      left: -5px;

      .logo-container-w {
        font-size: 0.7em;
      }
    }
    @media @lg {
      .logo-container-w {
        font-size: 1.1em;
      }

      .img {
        top: -16px;
      }
    }

  }

  .header-link-item-big:first-child {
    //padding-left: 20px;
  }

  .header-link-item-big {
    padding-left: 50px;

    & > a {
      padding: 4px 0;
    }
  }

  @media @xs {

  }

  @media @sm {

  }

  @media @md {

  }

  @media @lg {

  }

  @media @xl {

  }

  @media @xxl {

  }
}