.footer-container {

  margin-top: 150px;

  .top-footer {
    text-align: left;

    h3 {
      font-size: @fontSize + 0.5;
      color: #FFFFFF;
      margin: 0 0 30px 0;
    }

    a {
      text-align: left;
      padding: 0;
      color: #FFFFFF;
      margin-bottom: 6px;
    }

    background: #2E3192;
    color: #FFFFFF !important;
    position: relative;
    margin-top: 100px;

    .social-icons {
      margin-bottom: 10px;

      a {
        display: inline-block;
        margin-top: 30px;
        margin-right: 20px;

        &:first-child {
          @media @lg {
            margin-left: 55px;
          }
        }
      }
    }

    .round-bg {
      width: calc(100% + 1px);
      position: absolute;
      top: -65px;
      left: -1px;
      z-index: -1;
    }

    .logo-img {
      position: relative;

      .logo-text {
        position: absolute;
        left: 110px;
        top: 2px;
        font-size: @fontSize + 0.4em;
        line-height: 1em;
      }
    }

    .page-container {
      padding-top: 0;
    }
  }

  .bottom-footer {
    padding: 0 0 10px 0;
    background: #26297A;
    color: #fff !important;
    font-size: @fontSize - 0.2;

    a {
      color: #fff !important;
    }

    .page-container {
      padding-top: 10px;
    }
  }

  .footer-menu-container {
    &:after {
      position: absolute;
      content: '6+';
      display: block;
      right: 10px;
      top: 100px;
      font-size: 60px;
      color: rgb(144, 164, 255);

      @media @xs {
        top: 50px;
        right: 10px;
      }
      @media @md {
        top: 100px;
        right: -40px;
      }
      @media @lg {
        right: -20px;
      }

    }
  }

  @media @xs {

  }

  @media @sm {

  }

  .rules {
    display: block;
    text-align: center;
    width: 100%;
    padding: 10px 0;
  }

  @media @md {
    .rules {
      text-align: right;
      padding: 0;
    }
  }

  @media @lg {

  }

  @media @xl {

  }

  @media @xxl {

  }
}