.need-help-page,
.want-to-help-page {
  .email-input {
    border-width: 2px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 100px;
    text-align: center;
    max-width: 350px;
    box-shadow: none;
    &:hover ,
    &:focus {
        border-color: @yellowColor;
    }
    &.error{
        border-color: red;
    }
  }

  .providers-info-container {
    .grayBt {
      margin: 0 auto;
      display: block;
      width: max-content;
    }

    .provider-logo-container {

      .provider-logo {
        border-radius: 30px;
        border: 1px solid #EDEDED;
        min-height: 150px;
        overflow: hidden;
        position: relative;

        &.active,
        &:hover {
          border-color: @yellowColor;
          .pointer;
        }


        .badge {
          position: absolute;
          right: 12px;
          top: 10px;
          font-size: 8px;
          color: white;
          background: red;
          border-radius: 100px;
          width: 20px;
          height: 20px;
          padding: 4px 0;
        }
      }


      @media @lg {
        margin-left: 20px;
      }

      @media @xl {
        margin-left: 40px;
      }

      &:nth-child(1) {
        @media @xl {
          margin-left: 0;
        }
      }


      @media @xxl {
        margin-left: 54px;
      }

      &:nth-child(1) {
        @media @xxl {
          margin-left: 0;
        }
      }
    }
  }

  b {
    font-family: 'Core Rhino 55';
  }

  .wth-sep {

    height: 20px;
    @media @sm {
      height: 0;
    }
  }

  .custom-tabs-widget-menu-container-block {
    position: relative;
    top: 100%;
    margin-bottom: 30px !important;

    .custom-tabs-widget-menu-container {
      position: absolute;

      margin-top: 40px !important;
      padding: 8px 0;
      @media @xs {
        width: 100%;
      }
      @media @sm {
        width: max-content;
        display: block;
        padding: 0 6px 10px 6px;
        button {
          width: max-content !important;
          font-size: 0.7em;
        }
      }
      @media @md {
        width: auto !important;
        right: 0;
      }
    }

    @media @xs {
      margin-top: -25px;
    }
    @media @sm {
      top: 50% !important;
      margin-bottom: 50px !important;
    }
    @media @md {
      margin-top: 20px;
      button {
        width: max-content !important;
        font-size: 1em !important;
      }
    }
    @media @lg {
      margin-top: 20px;
    }
  }

  .ant-steps-item-title {
    opacity: 0.4;
  }

  .ant-steps-item-description {
    display: none;
  }

  .ant-steps-item-active {
    .ant-steps-item-title {
      opacity: 1;
    }

    .ant-steps-item-description {
      display: block;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-description {
      display: block;
    }

    .ant-steps-item-title {
      opacity: 1;
    }

    .hint {
      border-bottom: 1px dashed #cecece;
      font-weight: bold;
    }
  }

  .ant-steps-item-content {
    margin-bottom: 15px;
    @media @xs {
      width: 100%;
      .ant-steps-item-description,
      .ant-steps-item-title {
        text-align: center;
        display: block;
      }
    }
    @media @sm {
      width: auto;
      .ant-steps-item-description,
      .ant-steps-item-title {
        text-align: left;
      }
    }
  }

  .ant-steps-item-tail {
    @media @xs {
      display: none !important;
    }
    @media @sm {
      display: block !important;
    }
  }

  .ant-steps-item-icon {
    @media @xs {
      float: none !important;
      margin: 0 auto 25px auto !important;
      display: block !important;
    }
    @media @sm {
      float: left !important;
      display: inline-block !important;
      margin: 0 20px 0 0 !important;
    }
  }

  .hint {
    display: inline;
    @media @xs {
      &.l {
        text-align: left;
        display: inline;
      }

      padding-top: 0;
    }
  }

  .hint {
    @media @sm {
      display: inline-block;
      top: -10px;
      position: relative;
      font-size: @fontSize + 0.1;
    }
  }

  .hidden.hint {
    display: none;
  }

  .ant-steps-item-tail:after {
    background-color: transparent !important;
    border-left: 1px dashed #cecece;
    z-index: 0;
  }

  .ant-steps-item-finish .ant-steps-item-icon,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: @yellowColor;
    border-color: @yellowColor;
    .yellowShadow;

    path {
      fill: #fff;
    }
  }


  .payment-methods {
    @media @xs {
      margin-top: 30px;
    }
    @media @sm {
      margin-top: 0;
    }

    .item {
      overflow: hidden;
      border: 1px solid #ececec;
      margin-bottom: 15px;
      position: relative;


      &:hover {
        border: 1px solid #c9c9c9;
      }

      @media @xs {
        border-radius: 15px;
        margin-bottom: 10px;
      }
      @media @sm {
        border-radius: 20px;
        margin-bottom: 15px;
      }
      @media @md {
        border-radius: 30px;
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        opacity: 0 !important;
        min-height: 140px;
      }

      .bg {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 100%;
        margin: 0 auto;
        border-radius: 30px;

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          background-color: #fff;
          @media @xs {
            height: 2.03em;
          }
          @media @sm {
            height: 1em;

          }
        }

        &:after {
          bottom: 0;
        }

      }
    }

  }

  @media @xs {
    .selected-payment-method {
      width: 100px;
      height: 58px;
      border: 1px solid #cecece;
      position: absolute;
      right: -10px;
      top: -2px;
      border-radius: 15px;
      background-position: center;
      background-size: 8em;
      overflow: hidden;
    }
  }

  @media @sm {
    .selected-payment-method {
      width: 115px;
      height: 63px;
      border: 1px solid #cecece;
      right: 0;
      top: -40px;
      background-size: 10em;
    }
  }

  @media @md {
    .selected-payment-method {
      width: 126px;
      height: 73px;
    }
  }

  .selected-payment-method {

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 0.42em;
      background: #fff;
    }

    &:after {
      bottom: 0;
    }
  }

  .gray-block {
    padding: 35px;
  }

  .amount-bt-container {
    margin: 15px 0 -20px 0;

    button {
      border-radius: 150px;
      width: 100%;
      padding: 20px 0;
      line-height: 0;
      border-width: 3px;
      margin-bottom: 30px;

      &:focus,
      &:visited,
      &:active,
      &.active {
        color: #FF8B00;
        border: 3px solid rgba(255, 139, 0, 0.3);
      }

      &:hover {
        border: 3px solid rgba(255, 139, 0, 0.3);
        color: #FF8B00;
      }

      &.active {
        background: rgba(255, 139, 0, 0.1);
      }
    }

    .change-default-amount {
      position: relative;
      text-align: center;

      &.change {
        > span {
          top: 10px;
          font-size: 0.8em;
          position: absolute;
          width: 100%;
          text-align: center;
          display: block !important;
        }
      }

      .ant-input-number {
        box-shadow: none;
        width: 100%;
        border: none;
        background: transparent;
        outline: none;
        top: -6px;
        text-align: center;

        .ant-input-number-handler-wrap {
          display: none;
        }

        input {
          box-shadow: none;
          text-align: center;
          font-size: 16px;
        }
      }
    }
  }

  .ant-steps {
    .ant-steps-item:last-child {
      .ant-steps-item-tail {
        display: none !important;
      }
    }
  }

  .transfer-money-block {

    color: #333;
    font-size: @fontSize - 0.01;

    h2 {
      padding: 0;
      margin-bottom: 20px;
    }

    .gray-block {
      font-size: @fontSize - 0.1;
      text-align: left;
    }

    .table-info-widget {
      @media @xs {
        margin-top: 30px;
      }
      @media @xl {
        margin-top: 0;
      }

      .table-info-widget-item {
        padding: 20px 25px;
      }
    }

    .yellowBt {
      line-height: 0;
    }
  }

  .ant-statistic-content-value {
    .font-slim;
    font-size: @fontSize - 0.3;
  }
}

.logo-container {
  text-align: left;
  position: relative;

  @media @xs {
    margin: 55px auto 0 auto;
    width: 333px;
    img {
      width: 74px;
    }

    span {
      &:first-child {
        display: inline-block;
        padding-top: 23px;
        position: relative;
        top: -20px;
      }

      &:last-child {
        top: -30px;
        display: inline-block;
        position: relative;
      }

    }
  }


  @media @xl {
    width: 433px;
    margin: 30px 0 0 0;
    img {
      width: 94px;
    }

    span {
      &:first-child {
        display: inline-block;
        padding-top: 20px;
        position: relative;
        top: -10px;
      }

      &:last-child {
        top: -25px;
        display: inline-block;
        position: relative;
      }

    }
  }

  @media @xxl {
    width: 433px;
    margin: 0;
    img {
      width: 94px;
    }

    span {
      &:first-child {
        display: inline-block;
        padding-top: 20px;
        position: relative;
        top: -10px;
      }

      &:last-child {
        top: -25px;
        display: inline-block;
        position: relative;
      }

    }
  }
}

.payment-result-page {
  height: 60vh;
  background-repeat: no-repeat;
  min-height: 500px;
  width: 100%;
  position: relative;
  left: 0;
  z-index: 0;
  top: -450px;
  margin-bottom: -535px;
}
