.round-tabs {
  overflow: visible !important;
  //* {
  //  overflow: visible !important;
  //}

  margin: 25px 0;

  .ant-tabs-nav {
    &:before {
      border-bottom: none !important;
    }
  }

  .ant-tabs-nav-list {
    margin: 0 auto;
    background: #F4F6F7;
    border-radius: 25px;
  }

  &.left .ant-tabs-nav-list {
    margin: 20px 0;
  }

  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: @colorMuted;
    }

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: inherit;
      text-shadow: 0 0 0.25px currentColor;
    }

    &:hover {
      color: inherit;
    }
  }

  .ant-tabs-tab {
    margin: 5px;
    padding: 10px 32px;
    border-radius: 100px;

    &.ant-tabs-tab-active {
      background-color: #FFFFFF;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    }
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: transparent;
    pointer-events: none;
  }

  .ant-tabs-tab {

    @media @xs {
      * {
        font-size: @fontSize - 0.08;
      }

      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    @media @sm {
      * {
        font-size: @fontSize;
      }
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

